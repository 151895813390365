import Image, { StaticImageData } from 'next/image';
import Button from '../Button';
import { useTranslations } from 'next-intl';
import styles from './index.module.scss';
import { useAppSelector } from '@/lib/hooks';
import { RootState } from '@/lib/store';
import { isDesktop } from '@/utils/device';

const ErrorScreen: React.FC<{
  image: StaticImageData;
  alt: string;
  title: string;
  subTitle: string;
  btnText: string;
  action?: () => void;
  btnVariant?: string;
  imageSpec: number;
  className?: string;
}> = ({
  image,
  alt,
  title,
  subTitle,
  btnText,
  action,
  btnVariant,
  imageSpec,
  className,
}) => {
  const t = useTranslations();
  const { orientation } = useAppSelector(
    (state: RootState) => state.device.deviceData
  );
  const handleClick = () => {
    if (action) {
      action();
    } else {
      window.location.reload();
    }
  };
  return (
    <div
      className={`${isDesktop() ? styles.desktopError : ''} ${
        styles.emptyHistory
      } ${styles[orientation as string]} ${className ? styles[className] : ''}`}
    >
      <Image src={image} alt={alt} width={imageSpec} />
      <p className={styles.titleText}>{t(title)}</p>
      <p className={styles.noChat}>{t(subTitle)}</p>
      <Button variant={btnVariant} action={handleClick} title={btnText} />
    </div>
  );
};

export default ErrorScreen;
