import { CharactersData } from "@/app/dashboard/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface ICharData {
    charactersData: CharactersData[],
}

const initialState: ICharData = {
    charactersData: [],
};

export const charSlice = createSlice({
    name: "feedData",
    initialState,
    reducers: {
        setCharData: (state, action: PayloadAction<CharactersData[]>) => {
            state.charactersData = action.payload
        },
    },
});

export const { setCharData } = charSlice.actions;
export const charReducer = charSlice.reducer;