import { CharFeedData } from "@/app/dashboard/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface IFeedData {
    charFeedData: CharFeedData[] | null,
    isNextpageAvailable: boolean,
    pageCursor: string | null
}

const initialState: IFeedData = {
    charFeedData: null,
    isNextpageAvailable: false,
    pageCursor: null
};

export const feedSlice = createSlice({
    name: "feedData",
    initialState,
    reducers: {
        setFeedData: (state, action: PayloadAction<CharFeedData[]>) => {
            state.charFeedData = action.payload
        },
        updateFeedCursor: (state, action: PayloadAction<{ isNextpageAvailable: boolean, pageCursor: string | null }>) => {
            const { isNextpageAvailable, pageCursor } = action.payload;
            state.isNextpageAvailable = isNextpageAvailable;
            state.pageCursor = pageCursor
        },
    },
});

export const { setFeedData, updateFeedCursor } = feedSlice.actions;
export const feedReducer = feedSlice.reducer;