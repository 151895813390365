'use client';

import ErrorContainer from '@/components/ErrorScreen/ErrorContainer';
import { getUtmParams } from '@/utils';
import { getFeDeviceDetails } from '@/utils/device';
import Bugsnag from '@bugsnag/js';
import { useEffect } from 'react';
import { useAppSelector } from '@/lib/hooks';
import { RootState } from '@/lib/store';

export default function Error({
  error,
}: {
  error: Error & { digest?: string };
}) {
  const { id } = useAppSelector((state: RootState) => state.user.userData);
  useEffect(() => {
    if (error) {
      Bugsnag.notify(error, (event) => {
        event.addMetadata('userData', {
          uaSessionId: window.sessionStorage.getItem('fe_uaSessionId'),
          uaId: window.localStorage.getItem('fe_uaId'),
          userId: id,
        });
        if (typeof window !== 'undefined') {
          event.addMetadata('clientInfo', {
            userAgent: navigator?.userAgent,
            visibilityState: document?.visibilityState,
            onLine: navigator?.onLine,
            utm: getUtmParams(),
            url: window?.location?.href,
            os: getFeDeviceDetails().feOsName,
            device: getFeDeviceDetails().feDeviceType,
          });
        }
      });
    }
  }, [error]);

  return <ErrorContainer />;
}
