const config = {
    qa: {
        apiKey: 'AIzaSyAYdx8hpO6-zkuiWvjQbcBGFDFdchdLmvM',
        authDomain: 'qa1-ai-berlin.firebaseapp.com',
        projectId: 'qa1-ai-berlin',
        storageBucket: 'qa1-ai-berlin.appspot.com',
        messagingSenderId: '857851280816',
        appId: '1:857851280816:web:a24902a10d774a62fe2a75',
    },
    staging: {
        apiKey: "AIzaSyBoeFT7u2Va0FwNnDBEq7SIaSU0pwKsLMg",
        authDomain: "staging1-ai.firebaseapp.com",
        projectId: "staging1-ai",
        storageBucket: "staging1-ai.appspot.com",
        messagingSenderId: "317713466894",
        appId: "1:317713466894:web:b3724d3b4303ea93395e5f"
    },
    prod: {
        apiKey: "AIzaSyA9FrPIX08nAnq-JxQxQhBU7r7CMqiPwWY",
        authDomain: "prod1-ai.firebaseapp.com",
        projectId: "prod1-ai",
        storageBucket: "prod1-ai.appspot.com",
        messagingSenderId: "829260107773",
        appId: "1:829260107773:web:adc187640f19d2c8da1394"
    }
}

export const getFirebaseConfig = () => {
    const hostName = window.location.hostname;
    if (hostName.includes('qa1-berlin') || hostName.includes('local')) {
        return config['qa'];
    } else if (hostName.includes('now.gg') || hostName.includes('wsup.ai')) {
        return config['prod'];
    } else if (hostName.includes('stagingngg.net')) {
        return config['staging'];
    }
    return config['qa'];
}