import Image from 'next/image';
import ErrorScreen from './Index';
import styles from './index.module.scss';
import errorImg from '/public/static/assets/images/error.png';
import logo from '/public/static/assets/images/splashLogo.png';
import { useAppSelector } from '@/lib/hooks';
import { RootState } from '@/lib/store';

const ErrorContainer: React.FC<{ action?: () => void }> = ({ action }) => {
  const { orientation } = useAppSelector(
    (state: RootState) => state.device.deviceData
  );
  return (
    <div
      className={`${styles.errorContainer} ${styles[orientation as string]}`}
    >
      <Image src={logo} alt="logo" className={styles.logo} />
      <ErrorScreen
        image={errorImg}
        alt="error"
        title={'somethingWentWrong'}
        subTitle={'errorText'}
        btnText={'reload'}
        imageSpec={72}
        action={action}
      />
    </div>
  );
};

export default ErrorContainer;
