import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type CardArray = {
    cardId: string;
    characterId: string;
    imageUrl: string;
};

export type IChatHistory = {
    type: string;
    data: {
        content: string,
        additional_kwargs?: {
            messageType?: string;
            cardDrop?: {
                message: string;
                cards: CardArray[];
                claimed: boolean;
                cardDropId: string;
                claimedCardId: string;
            };
            imageUrl?: string;
            isLoading?: boolean;
            requestId?: string;
            responseId?: string;
        }
    };
    chatId: string;
    requestId?: string;
    responseId?: string;
}

type CharChatHistoryData = {
    chatSessionId: string;
    characterId: string;
    user_id: string;
    id?: string;
    characterName: string;
    description?: string;
    characterImageUrl: string;
    createdAt?: {
        _seconds: string;
    };
    updatedAt?: {
        _seconds: number;
    };
    lastMessage?: string;
};

type ChatDataList = {
    chatHistoryList: IChatHistory[],
    chatSessionId: string,
    characterName: string,
    characterId: string,
    characterImageUrl: string,
    description: string,
    voiceId?: string;
    skillSet?: Record<string, boolean>,
    tip?: string,
    createdBy?: string
}
export interface IChatData {
    chatData: ChatDataList[],
    chatHistory: CharChatHistoryData[],
    chatCarouselData: CharChatHistoryData[],
    chatsGenerated: boolean,
}

const initialState: IChatData = {
    chatData: [],
    chatHistory: [],
    chatCarouselData: [],
    chatsGenerated: false
};

export const chatSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {
        setChatData: (state, action: PayloadAction<ChatDataList>) => {
            state.chatData = [...state.chatData, action.payload];
        },
        clearChatData: (state, action: PayloadAction<{ chatSessionId: string; chatHistoryList: IChatHistory[]; characterId: string; }>) => {
            const updatedChatData = [...state.chatData].map(chat => {
                if (chat.characterId === action.payload.characterId) {
                    chat.chatHistoryList = action.payload.chatHistoryList;
                    chat.chatSessionId = action.payload.chatSessionId;
                }
                return chat;
            });
            const updatedChatCarouselData = [...state.chatCarouselData].map(chat => {
                if (chat.characterId === action.payload.characterId) {
                    chat.chatSessionId = action.payload.chatSessionId;
                }
                return chat;
            });
            state.chatData = updatedChatData;
            state.chatCarouselData = updatedChatCarouselData;
        },
        updateChatData: (state, action: PayloadAction<IChatHistory>) => {
            state.chatData = state.chatData.map(chat => {
                if (chat.chatSessionId === action.payload.chatId) {
                    chat.chatHistoryList = [...chat.chatHistoryList, action.payload]
                }
                return chat
            })
        },
        deleteLastMessage: (state, action: PayloadAction<{ chatId: string }>) => {
            state.chatData = state.chatData.map(chat => {
                if (chat.chatSessionId === action.payload.chatId) {
                    chat.chatHistoryList = chat.chatHistoryList.slice(0, chat.chatHistoryList.length - 2);
                }
                return chat
            })
        },
        setClaimedCard: (state, action: PayloadAction<{ chatId: string; cardDropId: string; claimedCardId: string }>) => {
            state.chatData = state.chatData.map(chat => {
                if (chat.chatSessionId === action.payload.chatId) {
                    chat.chatHistoryList = chat.chatHistoryList.map(history => {
                        if (history.data.additional_kwargs?.cardDrop?.cardDropId === action.payload.cardDropId) {
                            history.data.additional_kwargs.cardDrop.claimed = true;
                            history.data.additional_kwargs.cardDrop.claimedCardId = action.payload.claimedCardId;
                        }
                        return history;
                    })
                }
                return chat
            })
        },
        updateChatDataImage: (state, action: PayloadAction<{ chatId: string, imageUrl: string }>) => {
            state.chatData = state.chatData.map(chat => {
                if (chat.chatSessionId === action.payload.chatId) {
                    chat.characterImageUrl = action.payload.imageUrl
                }
                return chat
            })
        },
        updateRegeneratedMessage: (state, action: PayloadAction<{ chatId: string, answer: string }>) => {
            state.chatData = state.chatData.map(chat => {
                if (chat.chatSessionId === action.payload.chatId) {
                    chat.chatHistoryList = chat.chatHistoryList.map((history, index) => {
                        if (index === chat.chatHistoryList.length - 1) {
                            history.data.content = action.payload.answer
                        }
                        return history;
                    })
                }
                return chat
            })
        },
        updateChatCarouselData: (state, action: PayloadAction<CharChatHistoryData>) => {
            state.chatCarouselData = [...state.chatCarouselData, action.payload]
        },
        updateChatCarouselDataatStart: (state, action: PayloadAction<CharChatHistoryData>) => {
            state.chatCarouselData = [action.payload, ...state.chatCarouselData]
        },
        setChatHistory: (state, action: PayloadAction<CharChatHistoryData[]>) => {
            state.chatHistory = action.payload
        },
        updateChatHistoryImage: (state, action: PayloadAction<{ chatId: string, imageUrl: string }>) => {
            state.chatHistory = state.chatHistory.map(chat => {
                if (chat.chatSessionId === action.payload.chatId) {
                    chat.characterImageUrl = action.payload.imageUrl
                }
                return chat
            })
        },
        updateChatCarouselDataImage: (state, action: PayloadAction<{ chatId: string, imageUrl: string }>) => {
            state.chatCarouselData = state.chatCarouselData.map(chat => {
                if (chat.chatSessionId === action.payload.chatId) {
                    chat.characterImageUrl = action.payload.imageUrl
                }
                return chat
            })
        },
        setChatsGenerated: (state, action: PayloadAction<boolean>) => {
            state.chatsGenerated = action.payload
        },
        updateImageResponse: (state, action: PayloadAction<{ chatId: string, data: { content: string } }>) => {
            state.chatData = state.chatData.map(chat => {
                if (chat.chatSessionId === action.payload.chatId) {
                    chat.chatHistoryList = chat.chatHistoryList.map((history, index) => {
                        if (index === chat.chatHistoryList.length - 1) {
                            history.data = action.payload.data
                        }
                        return history;
                    })
                }
                return chat
            })
        },
        updateLoadingState: (state, action: PayloadAction<string>) => {
            state.chatData = state.chatData.map(chat => {
                if (chat.chatSessionId === action.payload) {
                    chat.chatHistoryList = chat.chatHistoryList.map((history, index) => {
                        if (index === chat.chatHistoryList.length - 1) {
                            if (history.data.additional_kwargs) {
                                history.data.additional_kwargs.isLoading = false;
                            }
                        }
                        return history;
                    })
                }
                return chat
            })
        },
    },
});

export const { setChatData, clearChatData, updateChatData, deleteLastMessage, setChatHistory, updateLoadingState, updateImageResponse, updateChatCarouselData, updateChatCarouselDataatStart, setClaimedCard, setChatsGenerated, updateRegeneratedMessage, updateChatDataImage, updateChatCarouselDataImage, updateChatHistoryImage } = chatSlice.actions;
export const chatReducer = chatSlice.reducer;